import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import { Link } from "gatsby"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import React from "react"
import Image from "../Image"
import HostsQuery from "./HostsQuery"

export const Hosts = ({ hosts }) => (
  <Paper>
    {hosts.map((host, i) => (
      <div key={host.name}>
        {i ? <Divider /> : null}
        <ListItem
          display="flex"
          alignItems="center"
          button
          component={Link}
          to="/about"
        >
          <Avatar style={{ height: 60, width: 60 }}>
            <Image
              imageInfo={{
                image: host.headShot,
                alt: host.name,
              }}
            />
          </Avatar>
          <Box ml={1}>
            <Typography>{host.name}</Typography>
          </Box>
        </ListItem>
      </div>
    ))}
  </Paper>
)

export default () => (
  <HostsQuery
    render={hosts => (
      <Hosts hosts={hosts} />
    )}
  />
)
