import React from "react"
import { graphql, StaticQuery } from "gatsby"

export default ({ render }) => <StaticQuery query={graphql`
  query FullHostsQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "about" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            hosts {
              headShot {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              image {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              linkedIn
              name
            }
          }
        }
      }
    }
  }
`} render={(data) => render(data.allMarkdownRemark.edges[0].node.frontmatter.hosts)} />
