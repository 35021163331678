import React from "react"
import SEO from "../components/seo"
import { Link as GatsbyLink, graphql } from "gatsby"
import { Footer, FooterLinks } from "../components/Layout/Footer"
import Layout from "../components/Layout"
import Hosts from "../components/Hosts"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import ArrowForward from "@material-ui/icons/ArrowForward"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Box from "@material-ui/core/Box"
import kebabCase from "lodash/kebabCase"

const TagsPage = ({ data }) => {
  const { group } = data.allMarkdownRemark
  return (
    <Layout>
      <SEO title="Tags" />
      <Container style={{ paddingBottom: "20vh", paddingTop: 16 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" gutterBottom>
              Tags
            </Typography>
            <Paper>
              <List disablePadding>
                {group.map(tag => (
                  <ListItem
                    key={tag.fieldValue}
                    component={GatsbyLink}
                    to={`/tags/${kebabCase(tag.fieldValue)}/`}
                    button
                  >
                    <ListItemText
                      primary={tag.fieldValue}
                      secondary={`(${tag.totalCount})`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton size="small" component={GatsbyLink} to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                        <ArrowForward />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box position="sticky" top={0}>
              <Typography variant="h4" gutterBottom>
                Your Hosts
              </Typography>
              <Hosts />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
      <FooterLinks />
    </Layout>
  )
}

export const pageQuery = graphql`
  query TagsPage {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`

export default TagsPage
